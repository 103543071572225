select.select_normal_width {
  display: inline-block;
  width: 250px;
}


select.browser-default {
  min-width: 250px;
  height: 36px;
  border: 1px solid #ccc;
  box-shadow: none;
  cursor: pointer;
}

select.disabled-content {
    opacity: 0.5;
    pointer-events: none;
  }
