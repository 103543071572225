@import '../abstracts/default.scss';

.input-field {
  margin-top: 10px;
  input {
    box-sizing: border-box;
    min-width: 250px;
    height: 36px;
    margin: 0;
    padding: 0 10px;
    padding-left: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    background: #fff;
    box-shadow: none;
    font-size: 14px;
    cursor: pointer;
  }

  input.error {
    border-color: $error-color;
  }

  &.disabled-content {
    opacity: 0.5;
    pointer-events: none;
  }
}
