&.mat-stroked-button {
  border-width: 1px;
  border-color: $primary-inv-color;
  box-shadow: 0px 2px 4px transparentize(#000000, 0.8);
  color: $primary-inv-color;
  background-color: transparent;

  &:hover {
    color: $light-color;
  }

  &.mat-primary {
    border-color: $accent-color;
    color: $accent-color;

    &:hover {
      border-color: darken($accent-color, 10%);
      color: darken($accent-color, 10%);
    }
  }
}
