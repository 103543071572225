// @todo: needs refactoring https://omniaretail.atlassian.net/browse/PH-433
&.mat-link {
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: $accent-color;
  font-weight: bold;

  &.mat-button-disabled.mat-button-disabled,
  &.mat-button-disabled.mat-button-disabled:hover {
    color: $primary-inv-color;
  }

  &:hover {
    background: none;
    color: $accent-color;

    .leading-mat-button {
      text-decoration: underline;
    }

    .mat-button-focus-overlay {
      background: none;
    }
  }
}
