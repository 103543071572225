$primary-color: #3b1343;
$primary-inv-color: #fff;
$accent-color: #7c29a6;
$light-color: #e2dff5;
$secondary-color: #3d3d40;
$secondary-accent-color: #737377;
$secondary-light-color: #e8e8eb;
$card-background-odd: #F7F7F7;
$card-background-even: #f3f3f3;
$card-background: #f7f8f9;
$empty-list-text: #b1b1b1;
$card-content-border: #e5e7eb;
$black-opacity-50: #00000080;

// Toast background colors
$success-color: #5ebc87;
$processing-color: #7aa3eb;
$warning-color: #f4b86a;
$error-color: #ea5454;

// Banner colors
$banner-info-color: #d9edf7;
$banner-info-border-color: #bce8f1;
$banner-info-text-color: #31708f;

$banner-warning-color: #ffeeba;
$banner-warning-border-color: #fff3cd;
$banner-warning-text-color: #856404;


