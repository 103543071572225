&.mat-button {
  color: $primary-inv-color;

  &:hover {
    color: $light-color;
  }

  &.mat-primary {
    color: $accent-color;

    &:hover {
      color: darken($accent-color, 10%);
    }
  }

  &.mat-secondary {
    color: $primary-inv-color;

    &:hover {
      color: darken($primary-inv-color, 10%);
    }
  }
}
