// @todo: needs investigating if it's needed or not https://omniaretail.atlassian.net/browse/PH-433
&.mat-button-disabled.mat-button-disabled {
  border: none !important;
  background-color: $secondary-light-color !important;
  box-shadow: none !important;
  color: $primary-inv-color !important;

  &:hover {
    background-color: $secondary-light-color !important;
  }

  &.mat-icon-button {
    background-color: transparent !important;
    color: $secondary-light-color !important;
  }
}
