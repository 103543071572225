@import '../abstracts/default.scss';
.mat-snack-bar-container {
  a {
    @apply underline;
  }
  .mat-button:not(:disabled) {
    @apply bg-black bg-opacity-25 #{!important};
    box-shadow: none;

    &:hover {
      @apply bg-black bg-opacity-30 #{!important};
    }
  }
}

.default-dialog {
  background-color: $primary-inv-color;
  color: $secondary-color;

  a,
  .mat-button:not(:disabled),
  .leading-mat-button.text-base {
    color: $secondary-color;
  }
}

.success-dialog {
  background-color: $success-color;
  color: $primary-inv-color;

  a,
  .mat-button:not(:disabled),
  .leading-mat-button.text-base {
    color: $primary-inv-color;
  }
}

.error-dialog {
  background-color: $error-color;
  color: $primary-inv-color;

  a,
  .mat-button:not(:disabled),
  .leading-mat-button.text-base {
    color: $primary-inv-color;
  }
}

.warning-dialog {
  background-color: $warning-color;
  color: $primary-inv-color;

  a,
  .mat-button:not(:disabled),
  .leading-mat-button.text-base {
    color: $primary-inv-color;
  }
}

.info-dialog {
  background-color: $processing-color;
  color: $primary-inv-color;

  a,
  .mat-button:not(:disabled),
  .leading-mat-button.text-base {
    color: $primary-inv-color;
  }
}
