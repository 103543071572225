@use 'sass:color';
@import '../../abstracts/default.scss';

.mat-radio-button {
  color: inherit;
  font-family: inherit;
}

.mat-button-focus-overlay {
  background: $accent-color;
}

button.mat-button-base:not(.floating-icon-button) {
  box-sizing: content-box;
  min-width: auto;
  padding: 0 1.4rem;
  font-size: 1em;
  line-height: 2.5;

  @import './basic-button';
  @import './raised-button';
  @import './outlined-button';
  @import './link-button';
  @import './disabled-button';
}

// @todo move icons and animations into separate files https://omniaretail.atlassian.net/browse/PH-433
.mat-icon {
  &.text-xl {
    font-size: 1.625rem;
  }
}

.spinning-icon {
  animation: spinning 2s linear infinite;
  animation-direction: reverse;
}
