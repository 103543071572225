@mixin border($color: $primary-color, $width: 1px, $style: solid) {
  border: $style $width $color;
}

@mixin transition($prop: all 0.25s ease) {
  -webkit-transition: $prop;
  -moz-transition: $prop;
  -o-transition: $prop;
  -ms-transition: $prop;
  transition: $prop;
}

@mixin transform($prop: scale(1.02)) {
  -webkit-transition: $prop;
  -moz-transition: $prop;
  -o-transition: $prop;
  -ms-transition: $prop;
  transition: $prop;
}

@mixin user-select($value: none) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -khtml-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}
