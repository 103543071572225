&.mat-raised-button {
  background-color: $primary-inv-color;
  box-shadow: 0px 2px 4px transparentize(#000000, 0.8);
  color: $accent-color;

  &:hover {
    background-color: $light-color;
  }

  &:active {
    box-shadow: 0px 2px 4px transparentize(#000000, 0.8);
  }

  &.mat-primary {
    background-color: $accent-color;
    color: $primary-inv-color;

    &:hover {
      background-color: lighten($accent-color, 10%);
    }
  }
}
